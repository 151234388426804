import { GoLocation } from "react-icons/go";
import { ImMobile2 } from "react-icons/im";
import { AiOutlineMail } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillTwitterCircle } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillLinkedin } from "react-icons/ai";
import { AiFillGoogleCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import "../CSS/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="footer-main">
        <div className="footer-left-one">
          <h2 className="company-footer-title">J K FASHION</h2>
          <p className="company-footer-content">
            J K FASHION , an export-oriented home textile manufacturing company,
            established seventeen past dawn of new millennium with a vision to
            be a pioneer in its sector by investing in people , technology and
            quality products.
          </p>
        </div>
        <div className="footer-left">
          <h3 className="footer-title">Office Address</h3>
          <div className="footer-flex">
            <div>
              <GoLocation className="icon" />
            </div>
            <div>
              <h4 className="footer-content">J K FASHION</h4>
              <h5 className="footer-content">3/87-6, AARNA Web, LNS Street,</h5>
              <h5 className="footer-content">Vadivel Nagar, Karur-02</h5>
            </div>
          </div>
        </div>
        <div className="footer-right">
          <h3 className="footer-title">Links</h3>
          <div className="foot-links">
            <Link to="/home" className="footer-link">
              Home
            </Link>
            <Link to="/about" className="footer-link">
              About Us
            </Link>
            <Link to="/infra" className="footer-link">
              Infrastructure
            </Link>
            <Link to="/products" className="footer-link">
              Products
            </Link>
            <Link to="/contact" className="footer-link">
              Contact Us
            </Link>
          </div>
        </div>
        <div className="footer-middle">
          <h3 className="footer-title">Contact Us </h3>
          <div className="footer-flex">
            <div>
              <ImMobile2 className="icon" />
            </div>
            <div>
              <h4 className="footer-content">98475 66366</h4>
            </div>
          </div>
          <div className="footer-flex">
            <div>
              <AiOutlineMail className="icon" />
            </div>
            <div>
              <h4 className="footer-content">rohit@jkfashion.in</h4>
            </div>
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="social-links">
        <div>
          <AiFillFacebook className="social-icons" />
          <AiFillTwitterCircle className="social-icons" />
          <AiFillInstagram className="social-icons" />
          <AiFillLinkedin className="social-icons" />
          <AiFillGoogleCircle className="social-icons" />
        </div>
      </div>
      <p className="copyright">
        @Copyright. All rights reserved. Designed by
        <a href="http://interdeccaan.in/" target="-blank">
          {" "}
          Inter Deccaan Solutions
        </a>
      </p>
    </div>
  );
}
export default Footer;
