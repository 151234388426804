import React from "react";
import "../CSS/SubProduct.css";
import image2 from "../Assets/Living/Product-Main/CUSHION 2.jpg";
import image3 from "../Assets/Living/Product-Main/CUSHION 3.jpg";
import image4 from "../Assets/Living/Product-Main/CUSHION 01.jpg";
// import image6 from "../Assets/Living/Product-Main/CUSHION 6.jpg";
// import image7 from "../Assets/Living/Product-Main/CUSHION 7.jpg";
// import image8 from "../Assets/Living/Product-Main/CUSHION 8.jpg";
// import image9 from "../Assets/Living/Product-Main/CUSHION 9.jpg";
// import image10 from "../Assets/Living/Product-Main/CUSHION 10.jpg";
// import image11 from "../Assets/Living/Product-Main/CUSHION 11.jpg";
import image12 from "../Assets/Living/Product-Main/WINDOW PANNEL 2.jpg";
import image13 from "../Assets/Living/Product-Main/WINDOW PANNEL 3.jpg";
import image14 from "../Assets/Living/Product-Main/WINDOW PANNEL 4.jpg";
import image15 from "../Assets/Living/Product-Main/WINDOW PANNEL 5.jpg";
// import image16 from "../Assets/Living/Product-Main/WINDOW PANNEL 6.jpg";
// import image17 from "../Assets/Living/Product-Main/WINDOW PANNEL 7.jpg";
// import image18 from "../Assets/Living/Product-Main/WINDOW PANNEL 8.jpg";
import image16 from "../Assets/Living/Product-Main/THROWS 1.jpg";
import image17 from "../Assets/Living/Product-Main/THROWS 2.jpg";
import image18 from "../Assets/Living/Product-Main/THROWS 3.jpg";
import image19 from "../Assets/Living/Product-Main/SHOWER CURTAIN 2.jpg";
import image20 from "../Assets/Living/Product-Main/SHOWER CURTAIN 3.jpg";
import image21 from "../Assets/Living/Product-Main/SHOWER CURTAIN 4.jpg";
function Living() {
  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Cushion</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
        </div>
        <div className="sub-product-head">
          <h3>Window Panel</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image12} />
          <img src={image13} />
          <img src={image14} />
          {/* <img src={image15} /> */}
        </div>
        <div>
          <div className="sub-product-head">
            <h3>Throws</h3>
          </div>
          <div className="sub-product-flex">
            <img src={image16} />
            <img src={image17} />
            <img src={image18} />
            {/* <img src={image4} /> */}
          </div>
        </div>
        <div>
          <div className="sub-product-head">
            <h3>Shower Curtain</h3>
          </div>
          <div className="sub-product-flex">
            <img src={image19} />
            <img src={image20} />
            <img src={image21} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Living;
