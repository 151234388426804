import React from "react";
import "../CSS/SubProduct.css";
import tablecloth1 from "../Assets/Devour/Product_Main/TABLE CLOTH 1.jpg";
import tablecloth2 from "../Assets/Devour/Product_Main/TABLE CLOTH 2.jpg";
import tablecloth3 from "../Assets/Devour/Product_Main/TABLE CLOTH 3.jpg";
import tablecloth4 from "../Assets/Devour/Product_Main/TABLE CLOTH 4.jpg";

function TableCloth() {
  window.scrollTo(0, 0);

  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Table Cloth</h3>
        </div>
        <div className="sub-product-flex">
          <img src={tablecloth1} />
          <img src={tablecloth2} />
          <img src={tablecloth3} />
          <img src={tablecloth4} />
        </div>
      </div>
    </div>
  );
}
export default TableCloth;
