import React from "react";
import "../CSS/Contact.css";
import { ImLocation2 } from "react-icons/im";
import { BsTelephoneInboundFill } from "react-icons/bs";
import { FaMailBulk } from "react-icons/fa";
import { BsPinMapFill } from "react-icons/bs";

function Contact() {
  window.scrollTo(0, 0);

  return (
    <div className="contact-main">
      <div className="contact-left">
        <div className="contact-left-content">
          <div>
            <div className="contact-left-div">
              <div>
                <ImLocation2 className="contact-icon" />
              </div>
              <div>
                <h4>Address</h4>
                <p>J K FASHION</p>
                <p>3/87-6, AARNA Web, LNS Street,</p>
                <p> Vadivel Nagar, Karur-02</p>
              </div>
            </div>
            <div className="contact-left-div">
              <div>
                <BsTelephoneInboundFill className="contact-icon" />
              </div>
              <div>
                <h4>Lets Talk</h4>
                <p>98475 66366</p>
              </div>
            </div>
            <div className="contact-left-div">
              <div>
                <FaMailBulk className="contact-icon" />
              </div>
              <div>
                <h4>General Support</h4>
                <p>rohit@jkfashion.in</p>
              </div>
            </div>
            <div className="contact-left-map">
              <div className="map-div">
                <BsPinMapFill className="contact-icon" />
                <h4 className="map-title">Location</h4>
              </div>

              <iframe
                class="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?hl=en&amp;q=3/87-6, AARNA Web, LNS Street, Vadivel Nagar, Karur-02&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div className="contact-right">
        <h3 className="contact-right-title">Send Us A Message</h3>
        <div className="contact-input">
          <h4>Enter First Name *</h4>
          <input placeholder="First Name"></input>
        </div>
        <div className="contact-input">
          <h4>Enter Last Name *</h4>
          <input placeholder="Last Name"></input>
        </div>
        <div className="contact-input">
          <h4>Enter Your Email *</h4>
          <input placeholder="Your Mail"></input>
        </div>
        <div className="contact-input">
          <h4>Enter Phone Number</h4>
          <input placeholder="Your Number"></input>
        </div>
        <div>
          <h4>Message</h4>
          <input
            placeholder="Write us a message"
            className="description"
          ></input>
        </div>
        <div className="contact-submit">
          <button>Submit</button>
        </div>
      </div>
    </div>
  );
}
export default Contact;
