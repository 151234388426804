import React from "react";
import infra2 from "../Assets/Infra/infra5.jpg";
import infra3 from "../Assets/Infra/infra6.jpg";
import { FaBinoculars } from "react-icons/fa";
import { GiDiceTarget } from "react-icons/gi";
import { GiWorld } from "react-icons/gi";
import { MdStickyNote2 } from "react-icons/md";
import { MdProductionQuantityLimits } from "react-icons/md";
import { GiLargeDress } from "react-icons/gi";
import { SiStitcher } from "react-icons/si";
import { GiProgression } from "react-icons/gi";
import { VscServerProcess } from "react-icons/vsc";
import { MdBrandingWatermark } from "react-icons/md";
import "../CSS/About.css";

function About() {
  window.scrollTo(0, 0);
  const data = [
    {
      icon: <GiWorld className="profile-icon" />,
      title: "Domain Expertise",
      content: "Big Chains, Wholesalers, Retailers & independent Buyers",
    },
    {
      icon: <MdStickyNote2 className="profile-icon" />,
      title: "Compliance",
      content:
        "Our factories manufacture quality product that meet with Social Accountability compliance ",
    },
    {
      icon: <MdProductionQuantityLimits className="profile-icon" />,
      title: "Product Approach",
      content:
        "Combining modern aesthetics and textiles with functional features based on Standardised Sizes, Stringent Quality Protocols",
    },
    {
      icon: <GiLargeDress className="profile-icon" />,
      title: "Design Approach",
      content:
        "Our In-house design studio combines demographic, geographic, spatial, cultural, product aspects and brand aesthetics together",
    },
  ];

  const data2 = [
    {
      icon: <SiStitcher className="profile-icon" />,
      title: "Services Offered",
      content:
        "• Curating • Design • Sampling • Manufacturing  • Logistics  • Delivery",
    },
    {
      icon: <GiProgression className="profile-icon" />,
      title: "Our Process",
      content:
        "• Client Briefing • Mind Mapping • Mood boarding • Design • Sampling • Manufacturing • Delivery • Onsite Support",
    },
    {
      icon: <VscServerProcess className="profile-icon" />,
      title: "Customized Offerings",
      content:
        "From Value Engineering to Standardised to Bespoke Products, we cater to diverse market segments in the brand hierarchy",
    },
    {
      icon: <MdBrandingWatermark className="profile-icon" />,
      title: "Brand Offered",
      content:
        "Our approach to design and manufacturing products is basis our customers' vision, value, scale and aesthetics. We are ready to make all products with your expectations.",
    },
  ];

  return (
    <div className="infra-main">
      <h3 className="infra-main-title">Vision & Mission</h3>
      <div className="infra-main-flex1">
        <div className="interior-left">
          <h4 className="infra-vision-title">Vision</h4>
          <FaBinoculars className="infra-icon" />
          <p className="interior-left-content">
            Essentially an action oriented vision statement “ To create an
            environment that can offer superior design, quality and value to our
            customers “
          </p>
          <h4 className="infra-vision-title">Mision</h4>
          <GiDiceTarget className="infra-icon" />
          <p className="interior-left-content">
            To innovate, to lead, to enhance, to provide quality products
            amongst the vibrant textile economy driven by new and growing
            business.
          </p>
        </div>
        <div className="interior-right">
          <img src={infra2}></img>
        </div>
      </div>
      <div className="infra-middle">
        <div>
          <img src={infra3} className="infra1-mid-image"></img>
        </div>
        <div className="infra-mid-right">
          <h3 className="infra-vision-title">Our Testimonials</h3>
          <p className="interior-left-content">
            To establish the desired quality standards which are acceptable by
            the customers
          </p>
          <p className="interior-left-content">
            To discover flaws or variations in raw materials and the production
            processes to ensure smooth and uninterrupted flow of production.
          </p>
          <p className="interior-left-content">
            To evaluate the methods and process of production and suggest
            further improvements in their functioning.
          </p>
          <p className="interior-left-content">
            To study and determine the extent of quality deviation in a product
            during the manufacturing process and analyse in detail the causes
            responsible for such deviation.
          </p>
          <p className="interior-left-content">
            To undertake measures which are helpful in achieving the desired
            quality of the product.
          </p>
        </div>
      </div>

      {/* JK Propositions */}

      <div className="profile-proposition">
        <h2 className="profile-title">JK'S Proposition</h2>
        <div className="proposition-flex">
          {data.map((item, index) => (
            <div key={index} className="proposition-card">
              <div>
                {item.icon}
                <h4 className="card-title">{item.title}</h4>
                <p className="card-content">{item.content} </p>
              </div>
            </div>
          ))}
        </div>
        <div className="proposition-flex">
          {data2.map((item, index) => (
            <div key={index} className="proposition-card">
              <div>
                {item.icon}
                <h4 className="card-title">{item.title}</h4>
                <p className="card-content">{item.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default About;
