import React from "react";
import "../CSS/SubProduct.css";
import image1 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 1.jpg";
import image2 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 2.jpg";
import image3 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 3.jpg";
import image4 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL  4.jpg";

function KitchenTowel() {
  window.scrollTo(0, 0);

  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Kitchen Towel</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image1} />
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
        </div>
      </div>
    </div>
  );
}
export default KitchenTowel;
