import React from "react";
import product1 from "../Assets/Living/Product-Main/CUSHION 4.jpg";
import shower from "../Assets/Living/Product-Main/SHOWER CURTAIN 1.jpg";
import throws from "../Assets/Living/Product-Main/THROWS 1.jpg";
// import window from "../Assets/Living/Product-Main/WINDOW PANNEL 1.jpg";
// import placemat1 from "../Assets/Devour/Product_Main/PLACEMAT 1.jpg";
// import apron from "../Assets/Kitchen/Product_Main/APRON 1.jpg";
// import glove from "../Assets/Kitchen/Product_Main/GLOVE 1.jpg";
// import towel from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 1.jpg";
// import potholder from "../Assets/Kitchen/Product_Main/POTHOLDER 1.jpg";
// import runner1 from "../Assets/Devour/Product_Main/RUNNER 1.jpg";
// import tablecloth1 from "../Assets/Devour/Product_Main/TABLE CLOTH 1.jpg";
// import teacozy from "../Assets/Devour/Product_Main/TEA COZY.jpg";
import "../CSS/Product.css";
import { Link } from "react-router-dom";

function Products() {
  // window.scrollTo(0, 0);

  return (
    <div className="products">
      <div className="product-head">
        <h3 className="product-main-title">Our Product Range</h3>
        <p className="product-main-content">
          100% Cotton Handloom / Power loom made-ups & Fabrics of Dyed, Printed,
          Woven & Jacquard.
        </p>
      </div>
      <h3 className="product-sub-title">Product Categories</h3>
      <div className="product-image-flex">
        <div className="product-card-one">
          <Link to="/living">
            <img src={product1}></img>
            <div className="product-caption">
              <h3>Living Linen</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-two">
          <Link to="/kitchen">
            <img src={shower}></img>
            <div className="product-caption">
              <h3>Kitchen Linen</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-three">
          <Link to="/devour">
            <img src={throws}></img>
            <div className="product-caption">
              <h3>Devour Linen</h3>
            </div>
          </Link>
        </div>
      </div>

      {/* Kitchen Section */}

      {/* <h3 className="product-sub-title">Kitchen Linen</h3>
      <div className="product-image-flex">
        <div className="product-card-one">
          <Link to="/apron">
            <img src={apron}></img>
            <div className="product-caption">
              <h3>Apron</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-two">
          <Link to="/glove">
            <img src={glove}></img>
            <div className="product-caption">
              <h3>Glove</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-three">
          <Link to="/kitchentowel">
            <img src={towel}></img>
            <div className="product-caption">
              <h3>Kitchen Towel</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-one">
          <Link to="/potholder">
            <img src={potholder}></img>
            <div className="product-caption">
              <h3>Potholder</h3>
            </div>
          </Link>
        </div>
      </div>

      <h3 className="product-sub-title">Devour Linen</h3>
      <div className="product-image-flex">
        <div className="product-card-one">
          <Link to="/placemat">
            <img src={placemat1}></img>
            <div className="product-caption">
              <h3>Placemat</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-two">
          <Link to="/runner">
            <img src={runner1}></img>
            <div className="product-caption">
              <h3>Runner</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-three">
          <Link to="/tablecloth">
            <img src={tablecloth1}></img>
            <div className="product-caption">
              <h3>Table Cloth</h3>
            </div>
          </Link>
        </div>
        <div className="product-card-one">
          <Link to="/teacozy">
            <img src={teacozy}></img>
            <div className="product-caption">
              <h3>Tea Cozy</h3>
            </div>
          </Link>
        </div>
      </div> */}
    </div>
  );
}
export default Products;
