import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home";
import Products from "./Components/Products";
import Contact from "./Components/Contact";
import Footer from "./Layout/Footer";
import Nav from "./Components/Nav";
import About from "./Components/About";
import Cushion from "./Components/Cushion";
import ShowerCurtain from "./Components/ShowerCurtain";
import Throws from "./Components/Throws";
import WindowPanel from "./Components/WindowPanel";
import Apron from "./Components/Apron";
import Glove from "./Components/Glove";
import Potholder from "./Components/Potholder";
import Runner from "./Components/Runner";
import KitchenTowel from "./Components/KitchenTowel";
import TableCloth from "./Components/TableCloth";
import TeaCozy from "./Components/TeaCozy";
import Placemat from "./Components/Placemat";
import Infrastructure from "./Components/Infrastructure";
import Living from "./Components/Living";
import Kitchen from "./Components/Kitchen";
import Devour from "./Components/Devour";

function Main() {
  return (
    <>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />

          <Route path="/about" element={<About />} />
          <Route path="/infra" element={<Infrastructure />} />
          <Route path="/cushion" element={<Cushion />} />
          <Route path="/living" element={<Living />} />
          <Route path="/kitchen" element={<Kitchen />} />
          <Route path="/devour" element={<Devour />} />

          <Route path="/showercurtain" element={<ShowerCurtain />} />
          <Route path="/throws" element={<Throws />} />
          <Route path="/windowpanel" element={<WindowPanel />} />
          <Route path="/apron" element={<Apron />} />
          <Route path="/glove" element={<Glove />} />
          <Route path="/potholder" element={<Potholder />} />
          <Route path="/kitchentowel" element={<KitchenTowel />} />
          <Route path="/runner" element={<Runner />} />
          <Route path="/tablecloth" element={<TableCloth />} />
          <Route path="/teacozy" element={<TeaCozy />} />
          <Route path="/placemat" element={<Placemat />} />
          <Route path="/Products" element={<Products />} />
          <Route path="/Contact" element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}
export default Main;
