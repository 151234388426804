import React from "react";
import "../CSS/SubProduct.css";
import placemat1 from "../Assets/Devour/Product_Main/PLACEMAT 1.jpg";
import placemat2 from "../Assets/Devour/Product_Main/PLACEMAT 2.jpg";
import placemat3 from "../Assets/Devour/Product_Main/PLACEMAT 3.jpg";

function Placemat() {
  window.scrollTo(0, 0);

  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Placemat</h3>
        </div>
        <div className="sub-product-flex">
          <img src={placemat1} />
          <img src={placemat2} />
          <img src={placemat3} />
        </div>
      </div>
    </div>
  );
}
export default Placemat;
