import React from "react";
import "../CSS/SubProduct.css";
import runner1 from "../Assets/Devour/Product_Main/RUNNER 1.jpg";
import teacozy from "../Assets/Devour/Product_Main/TEA COZY.jpg";
import tablecloth1 from "../Assets/Devour/Product_Main/TABLE CLOTH 1.jpg";
import tablecloth2 from "../Assets/Devour/Product_Main/TABLE CLOTH 2.jpg";
import tablecloth3 from "../Assets/Devour/Product_Main/TABLE CLOTH 3.jpg";
import placemat1 from "../Assets/Devour/Product_Main/PLACEMAT 1.jpg";
import placemat2 from "../Assets/Devour/Product_Main/PLACEMAT 2.jpg";
import placemat3 from "../Assets/Devour/Product_Main/PLACEMAT 3.jpg";

function Kitchen() {
  return (
    <div className="product-main">
      <div>
        <div>
          <div className="sub-product-head">
            <h3>Runner</h3>
          </div>
          <div className="sub-product-flex">
            <img src={runner1} />
            <img src={teacozy} />
          </div>
        </div>
        <div className="sub-product-head">
          <h3>Table Cloth</h3>
        </div>
        <div className="sub-product-flex">
          <img src={tablecloth1} />
          <img src={tablecloth2} />
          <img src={tablecloth3} />
        </div>

        <div>
          <div className="sub-product-head">
            <h3>Place Mat</h3>
          </div>
          <div className="sub-product-flex">
            <img src={placemat1} />
            <img src={placemat2} />
            <img src={placemat3} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Kitchen;
