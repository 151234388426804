import React from "react";
// import main from "../Assets/Infra/infra3.jpg";
import main from "../Assets/Profile/9.jpg"
import cert1 from "../Assets/certificate4.png";
import cert2 from "../Assets/certificate5.png";
import cert3 from "../Assets/certificate6.png";
import cert4 from "../Assets/certificate7.png";
import infra3 from "../Assets/Infra/infra1.jpg";
import infra4 from "../Assets/Infra/infra2.jpg";
import { BsQuestionDiamondFill } from "react-icons/bs";
import { BiCurrentLocation } from "react-icons/bi";
import { GiWorld } from "react-icons/gi";
import { MdNoteAlt } from "react-icons/md";
import ReactPlayer from "react-player";
import video_bg from "../Assets/background-video2.mp4";

import "../CSS/Home.css";
function Home() {
  window.scrollTo(0, 0);

  return (
    <div className="home">
      <div className="home-back">
        <img src={main}></img>
      </div>

      <div className="history-main">
        <div className="background-top"></div>
        <div>
          <h3 className="history-title">Founder's History</h3>
          <div className="founder-history-one">
            <div>
              <p className="history-para">
                Steps into the field of textiles had begun when we had started
                our journey as a YARN TRADER in the early 90’s to date.Building
                up that business had been our initial focus and has now grown up
                to a really big segment in our business lineup.Exploring the
                textile Industry and adding value to the yarn we had been
                selling has always been a dream and we had initial plans of
                setting up a textile unit to serve the purpose.
              </p>
              <p className="history-para">
                Adding up to the line of business, we also engage in MINING BLUE
                METALS which supports to be an added advantage to our textiles
                as we are free of flow of funds.We engage in the manufacture of
                Blue Metals and supplying them to 90% customers all over our
                native territory.
              </p>
            </div>
            <div>
              <img src={infra3}></img>
            </div>
          </div>
          <div className="founder-history-one">
            <div>
              <img src={infra4}></img>
            </div>
            <div>
              <p className="history-para">
                We had defined our company in the home Textile Industry as “ J K
                FASHION ” since 2017 and carrying on successful business till
                date. We engage in affordable products without compromise in
                quality.Sourcing has always been our advantage in offering best
                price possible in the market.we had begun slowly into textiles
                with an initial set up but now have grown up with a separate
                corporate office and an individual factory set up to engage in
                volume business.
              </p>
              <p className="history-para">
                Pioneering new designs has always been our aim and offering
                innovation is our prime concern. Our focus is always giving out
                the market something new. We would always be happy to become the
                prima-facie of the customer via the service we provide or rather
                be the quality that we would offer.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="home-bottom-section">
        {/* <div className="background-two"></div> */}
        <div className="home-bottom-flex">
          <div className="bottom-card-one">
            <div className="bottom-left">
              <BsQuestionDiamondFill className="home-icon" />
            </div>
            <div className="bottom-right">
              <h3 className="home-card-title">What </h3>
              <h4 className="home-card-title">can we do for you ?</h4>
              <p className="home-card-content">
                We offer newness in Quality and Quantity !
              </p>
            </div>
          </div>
          <div className="bottom-card-two">
            <div className="bottom-left">
              <BiCurrentLocation className="home-icon" />
            </div>
            <div className="bottom-right">
              <h3 className="home-card-title">Why </h3>
              <h4 className="home-card-title">Work with us ?</h4>
              <p className="home-card-content">
                Best prices and MOQ We offer a smooth process assigned with a
                key account manager who ensures right quality at right time
              </p>
            </div>
          </div>
          <div className="bottom-card-three">
            <div className="bottom-left">
              <GiWorld className="home-icon" />
            </div>
            <div className="bottom-right">
              <h3 className="home-card-title">Who </h3>
              <h4 className="home-card-title">Work with us ?</h4>
              <p className="home-card-content">
                We are open to Big Chain stores, wholesalers,Distributers,
                catalogue Companies and online stores
              </p>
            </div>
          </div>
          <div className="bottom-card-four">
            <div className="bottom-left">
              <MdNoteAlt className="home-icon" />
            </div>
            <div className="bottom-right">
              <h3 className="home-card-title">How </h3>
              <h4 className="home-card-title">To Work with us ?</h4>
              <p className="home-card-content">
                Feel free to contact any of our representative. It is our
                pleasure to visit and demonstrate our collections.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="youtube-section">
        <video autoPlay muted loop className="background-video">
          <source src={video_bg} type="video/mp4" />
        </video>
        <div>
          <div className="content">
            <div className="youtube-left">
              <ReactPlayer
                className="video"
                url="https://youtu.be/8Jn7TYnJjZI"
              />
            </div>
            <div className="youtube-right">
              <p>
                J K Fashion has a manufacturing facility spanning over 40,000
                square feet in Karur. It houses our state-of-the-art production
                line, as well an in-house design studio. Our industrial
                engineers work in tandem with our intricate machinery to make
                sure the entire production process is carried out without flaw.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Cerifications */}
      <div className="certification">
        <div className="background"></div>
        <div>
          <h3 className="infra-title">Certifications</h3>
          <div className="certificate-flex">
            <img src={cert1}></img>
            <img src={cert2}></img>
            <img src={cert3}></img>
            <img src={cert4}></img>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home;
