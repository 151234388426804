import React from "react";
import "../CSS/SubProduct.css";

import image1 from "../Assets/Kitchen/Product_Main/APRON 1.jpg";
import image2 from "../Assets/Kitchen/Product_Main/APRON 2.jpg";
import image3 from "../Assets/Kitchen/Product_Main/GLOVE 1.jpg";
import image4 from "../Assets/Kitchen/Product_Main/GLOVE 2.jpg";
import image5 from "../Assets/Kitchen/Product_Main/GLOVE 3.jpg";
import image6 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 1.jpg";
import image7 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 2.jpg";
import image8 from "../Assets/Kitchen/Product_Main/KITCHEN TOWEL 3.jpg";
import image9 from "../Assets/Kitchen/Product_Main/POTHOLDER 1.jpg";
import image10 from "../Assets/Kitchen/Product_Main/POTHOLDER 2.jpg";
import image11 from "../Assets/Kitchen/Product_Main/POTHOLDER 3.jpg";

function Kitchen() {
  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Apron</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image1} />
          <img src={image2} />
          {/* <img src={image3} /> */}
        </div>

        <div>
          <div className="sub-product-head">
            <h3>Kitchen Towel</h3>
          </div>
          <div className="sub-product-flex">
            <img src={image6} />
            <img src={image7} />
            <img src={image8} />
          </div>
        </div>
        <div>
          <div className="sub-product-head">
            <h3>Potholder</h3>
          </div>
          <div className="sub-product-flex">
            <img src={image9} />
            <img src={image10} />
            <img src={image11} />
          </div>
        </div>
        <div className="sub-product-head">
          <h3>Gloves</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
          {/* <img src={image15} /> */}
        </div>
      </div>
    </div>
  );
}
export default Kitchen;
