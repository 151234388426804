import React from "react";
import "../CSS/SubProduct.css";
import runner1 from "../Assets/Devour/Product_Main/RUNNER 1.jpg";

function Runner() {
  window.scrollTo(0, 0);

  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Runner</h3>
        </div>
        <div className="sub-product-flex">
          <img src={runner1} />
          <img src={runner1} />
        </div>
      </div>
    </div>
  );
}
export default Runner;
