import React from "react";
import "../CSS/SubProduct.css";
import image1 from "../Assets/Kitchen/Product_Main/APRON 1.jpg";
import image2 from "../Assets/Kitchen/Product_Main/APRON 2.jpg";

function Apron() {
  window.scrollTo(0, 0);

  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Apron</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image1} />
          <img src={image2} />
          {/* <img src={image1} />
          <img src={image1} /> */}
        </div>
      </div>
    </div>
  );
}
export default Apron;
