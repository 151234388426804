import React from "react";
import "../CSS/SubProduct.css";
import image1 from "../Assets/Living/Product-Main/WINDOW PANNEL 2.jpg";
import image2 from "../Assets/Living/Product-Main/WINDOW PANNEL 3.jpg";
import image3 from "../Assets/Living/Product-Main/WINDOW PANNEL 4.jpg";
import image4 from "../Assets/Living/Product-Main/WINDOW PANNEL 5.jpg";
import image5 from "../Assets/Living/Product-Main/WINDOW PANNEL 6.jpg";
import image6 from "../Assets/Living/Product-Main/WINDOW PANNEL 7.jpg";
import image7 from "../Assets/Living/Product-Main/WINDOW PANNEL 8.jpg";

function WindowPanel() {
  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Window Panel</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image1} />
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
        </div>
        <div className="sub-product-flex">
          <img src={image5} />
          <img src={image6} />
          <img src={image7} />
        </div>
      </div>
    </div>
  );
}
export default WindowPanel;
