import React from "react";
import "../CSS/SubProduct.css";
import image2 from "../Assets/Living/Product-Main/CUSHION 2.jpg";
import image3 from "../Assets/Living/Product-Main/CUSHION 3.jpg";
import image4 from "../Assets/Living/Product-Main/CUSHION 01.jpg";
import image5 from "../Assets/Living/Product-Main/CUSHION 5.jpg";
import image6 from "../Assets/Living/Product-Main/CUSHION 6.jpg";
import image7 from "../Assets/Living/Product-Main/CUSHION 7.jpg";
import image8 from "../Assets/Living/Product-Main/CUSHION 8.jpg";
import image9 from "../Assets/Living/Product-Main/CUSHION 9.jpg";
import image10 from "../Assets/Living/Product-Main/CUSHION 10.jpg";
import image11 from "../Assets/Living/Product-Main/CUSHION 11.jpg";

function Cushion() {
  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Cushion</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
          <img src={image5} />
        </div>
        <div className="sub-product-flex">
          <img src={image6} />
          <img src={image7} />
          <img src={image8} />
          <img src={image9} />
        </div>
        <div className="sub-product-flex">
          <img src={image10} />
          <img src={image11} />
        </div>
      </div>
    </div>
  );
}
export default Cushion;
