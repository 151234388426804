import React from "react";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "../CSS/Infra.css";
import image1 from "../Assets/Profile/1.jpg";
import image2 from "../Assets/Profile/2.jpg";
import image3 from "../Assets/Profile/3.jpg";
import image4 from "../Assets/Profile/4.jpg";
import image5 from "../Assets/Profile/5.jpg";
import image6 from "../Assets/Profile/6.jpg";
import image7 from "../Assets/Profile/7.jpg";
import image8 from "../Assets/Profile/8.jpg";
import image9 from "../Assets/Profile/9.jpg";
import image10 from "../Assets/Profile/10.jpg";
import image11 from "../Assets/Profile/11.jpg";
import image12 from "../Assets/Profile/12.jpg";
import image13 from "../Assets/Profile/13.jpg";
import image14 from "../Assets/Profile/14.jpg";
import image15 from "../Assets/Profile/15.jpg";
import image16 from "../Assets/Profile/16.jpg";
import image17 from "../Assets/Profile/17.jpg";
import image18 from "../Assets/Profile/18.jpg";
import image19 from "../Assets/Profile/19.jpg";
import image20 from "../Assets/Profile/20.jpg";
import image21 from "../Assets/Profile/21.jpg";
import slide1 from "../Assets/Slide/slide1.jpg";
import slide2 from "../Assets/Slide/slide2.jpg";
import slide3 from "../Assets/Slide/slide3.jpg";
import slide4 from "../Assets/Slide/slide4.jpg";
import slide5 from "../Assets/Slide/slide5.jpg";

function Profile() {
  return (
    <div className="infra-main-page">
      <Carousel>
        <Carousel.Item interval={1000}>
          <img className="image-carousel" src={slide1} alt="First slide" />
        </Carousel.Item>
        <Carousel.Item interval={500}>
          <img className="image-carousel" src={slide2} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="image-carousel" src={slide3} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="image-carousel" src={slide4} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item interval={1000}>
          <img className="image-carousel" src={slide5} alt="Third slide" />
        </Carousel.Item>
      </Carousel>

      <div className="gallery">
        <div>
          <img src={image1}></img>
        </div>
        <div className="v-stretch">
          <img src={image2}></img>
        </div>
        <div className="h-stretch">
          <img src={image3}></img>
        </div>
        <div>
          <img src={image4}></img>
        </div>
        <div>
          <img src={image5}></img>
        </div>
        <div className="v-stretch">
          <img src={image6}></img>
        </div>
        <div className="big-stretch">
          <img src={image7}></img>
        </div>
        <div>
          <img src={image8}></img>
        </div>
        <div className="h-stretch">
          <img src={image9}></img>
        </div>
        <div>
          <img src={image10}></img>
        </div>
        <div>
          <img src={image11}></img>
        </div>
        <div>
          <img src={image12}></img>
        </div>
        <div className="v-stretch">
          <img src={image13}></img>
        </div>
        <div>
          <img src={image14}></img>
        </div>
        <div className="big-stretch">
          <img src={image15}></img>
        </div>
        <div>
          <img src={image16}></img>
        </div>
        <div className="h-stretch">
          <img src={image17}></img>
        </div>
        <div>
          <img src={image18}></img>
        </div>
        <div className="big-stretch">
          <img src={image19}></img>
        </div>
        <div>
          <img src={image20}></img>
        </div>
        <div>
          <img src={image21}></img>
        </div>
        <div className="h-stretch">
          <img src={image19}></img>
        </div>
      </div>
    </div>
  );
}
export default Profile;
