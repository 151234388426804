import React from "react";
import "../CSS/SubProduct.css";
import image1 from "../Assets/Living/Product-Main/SHOWER CURTAIN 2.jpg";
import image2 from "../Assets/Living/Product-Main/SHOWER CURTAIN 3.jpg";
import image3 from "../Assets/Living/Product-Main/SHOWER CURTAIN 4.jpg";
import image4 from "../Assets/Living/Product-Main/SHOWER CURTAIN 5.jpg";

function ShowerCurtain() {
  return (
    <div className="product-main">
      <div>
        <div className="sub-product-head">
          <h3>Shower Curtain</h3>
        </div>
        <div className="sub-product-flex">
          <img src={image1} />
          <img src={image2} />
          <img src={image3} />
          <img src={image4} />
        </div>
      </div>
    </div>
  );
}
export default ShowerCurtain;
